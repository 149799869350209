/* eslint-disable max-statements */
import type { MenuProps } from 'antd';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  Empty,
  message,
  Popover,
  Skeleton,
  Tabs,
  TabsProps,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import lodash from 'lodash';
import * as React from 'react';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { UserOutlined } from '@ant-design/icons';
import { imgAvatar, ImgBackHome, ImgLogout } from '@assets/images';
import CONFIG from '@config';
import { PaginationEntity } from '@core/pagination/entity';
import store from '@core/store/redux';
import { getListDateNotify, removeDuplicateItems } from '@helper/functions';
import { RootState } from '@modules';
import authenticationPresenter from '@modules/authentication/presenter';
import profileStore, { removeProfile } from '@modules/authentication/profileStore';
import mediasPresenter from '@modules/medias/mediasPresenter';
import newsPresenter from '@modules/newsManagement/news/newsPresenter';
import NotificationsEntity, {
  eventType,
  GroupNotify,
  SeenStatus,
} from '@modules/notifications/notificationsEntity';
import notificationsPresenter from '@modules/notifications/notificationsPresenter';
import organizationsStore, {
  OrganizationsSelector,
} from '@modules/organizations/organizationStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import TariffPackagesPresenter from '@modules/tariffPackages/tariffPackagesPresenter';
import { typeUser } from '@modules/user/entity';
import ChangeLanguage from '@shared/components/ChangeLanguage';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import { useSingleAsync } from '@shared/hook/useAsync';
import useClickOutside from '@shared/hook/useClickOutside';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerNewsDetail } from '@view/NewsManagement/router';
import { routerOrganizations } from '@view/Organizations/router';
import { routerMediaInfo } from '@view/SourceInformation/Pages/Media/PageInfo/router';
import { routerMediaShares } from '@view/SourceInformation/Pages/MediaShares/router';
import { routerTariffInfo } from '@view/Tariff/Pages/TariffInfo/router';

import IFrame from './Iframe';

interface IPaginationEntity extends PaginationEntity {
  totalPages?: number;
}
const HeaderComponent = () => {
  const { user } = useSelector((state: RootState) => state.profile);
  const { formatMessage, intl, translateString } = useAltaIntl();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const format = 'YYYY-MM-DD';
  const getListNotificationCall = useSingleAsync(notificationsPresenter.getListNotifications);
  const notificationsMarkAllAsSeenCall = useSingleAsync(
    notificationsPresenter.notificationsMarkAllAsSeen,
  );
  const notificationsMarkAsSeenManyCall = useSingleAsync(
    notificationsPresenter.notificationsMarkAsSeenMany,
  );
  const { language } = useSelector(LanguageSelector);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [listDate, setListDate] = React.useState<string[]>([]);
  const [filterGroup, setFilterGroup] = React.useState<number>(GroupNotify.INTERNAL);
  const [listNotification, setListNotification] = React.useState<NotificationsEntity[]>([]);
  const organization = useSelector(OrganizationsSelector);
  const [infoNotify, setInfoNotify] = React.useState<{
    info: IPaginationEntity | null;
    moreInfo: any;
  }>({
    info: null,
    moreInfo: null,
  });
  const [isDown, setIsDown] = React.useState<boolean>(false);
  const refDropdown = React.useRef(null);
  const refNotify = React.useRef(null);

  useClickOutside([refDropdown], () => {
    setIsDown(false);
  });
  useClickOutside([refNotify], () => {
    setOpen(false);
  });

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleGetListNotificationCall = (current: number = 1, group?: number) => {
    setLoading(true);
    getListNotificationCall
      .execute(
        { current: current, pageSize: 5 },
        {
          filter: {
            group: { value: group, op: '$eq' },
          },
        },
      )
      .then(res => {
        const mergeArr = removeDuplicateItems([...res.data, ...listNotification]);
        setListDate(getListDateNotify(mergeArr));
        setListNotification(res.data);
        setLoading(false);
        setInfoNotify({
          info: res.info,
          moreInfo: res.moreInfo,
        });
      })
      .catch(console.error);
  };

  React.useEffect(() => {
    handleGetListNotificationCall(1, filterGroup);
    // const a = setInterval(() => {
    //   handleGetListNotificationCall(1, filterGroup);
    // }, 3000);
    // return () => clearInterval(a);
  }, [filterGroup]);

  const replaceContent = item => {
    let result = item.content;
    item.args.forEach(arg => {
      const pattern = new RegExp(`{${arg.key}}`, 'g');
      result = result.replace(pattern, `<strong>${arg.value}</strong>`);
    });
    return <span dangerouslySetInnerHTML={{ __html: translateString(result) }} />;
  };

  const switchRouter = items => {
    if (items.seenStatus === SeenStatus.UNSEEN) {
      notificationsMarkAsSeenManyCall
        .execute([items.id])
        .then(() => {
          getListNotificationCall.execute().then(rs => {
            const updatedArr = listNotification.map(item => {
              const matchingItem = rs.data.find(it => it.id === item.id);
              return matchingItem ? matchingItem : item;
            });
            setListNotification(updatedArr);
            setInfoNotify(prev => {
              return {
                ...prev,
                moreInfo: rs.moreInfo,
              };
            });
          });
        })
        .catch(console.error);
    }
    setOpen(false);
    switch (items.eventType) {
      case eventType.ApproveMedia:
      case eventType.RefuseMediaApproval:
      case eventType.ReceptionMedia:
      case eventType.MEDIA_NEED_APPROVE:
        mediasPresenter
          .getDetailMedias(items.refId)
          .then(() => {
            return navigation(generatePath(routerMediaInfo.path, { id: items.refId }));
          })
          .catch(err => {
            return message.error(formatMessage(err.response.data.message));
          });
        break;
      case eventType.ShareMedia:
        return navigation(routerMediaShares.path);
      case eventType.ApproveNewsletter:
      case eventType.RefuseNewsletterApproval:
      case eventType.ScheduleConflict:
      case eventType.NEWSLETTER_NEED_APPROVE:
        newsPresenter
          .getDetailNews(items.refId)
          .then(() => {
            return navigation(generatePath(routerNewsDetail.path, { id: items.refId }));
          })
          .catch(err => {
            return message.error(formatMessage(err.response.data.message));
          });
        break;
      case eventType.TariffPackageExpiringSoon:
      case eventType.DataAlmostFull:
        TariffPackagesPresenter.getDetailTariffPackages(items.refId)
          .then(() => {
            return navigation(generatePath(routerTariffInfo.path, { id: items.refId }));
          })
          .catch(err => {
            return message.error(formatMessage(err.response.data.message));
          });
        break;
      default:
        return;
    }
  };

  const time = createAt => {
    const createAtDate = dayjs(createAt);
    const now = dayjs();
    const diffMinutes = now.diff(createAtDate, 'minute');
    const diffHours = now.diff(createAtDate, 'hours');
    return diffMinutes > 60
      ? intl.formatMessage(
          { id: 'notifications.hour' },
          {
            value: diffHours,
          },
        )
      : intl.formatMessage(
          { id: 'notifications.time' },
          {
            value: diffMinutes,
          },
        );
  };

  const TabContent = React.useMemo(() => {
    const loopSkeleton = number => {
      return Array.from({ length: number }, (_, index) => <Skeleton key={index} active />);
    };
    return (
      <div className="content">
        {loading ? (
          <>{loopSkeleton(4)}</>
        ) : (
          <>
            {lodash.isEmpty(listDate) ? (
              <Empty className="mt-4" />
            ) : (
              listDate.map(it => (
                <>
                  <Divider orientation="left" className="title">
                    {dayjs(it).format(format) === dayjs().format(format)
                      ? formatMessage('notifications.today')
                      : dayjs(it).format('DD-MM-YYYY')}
                  </Divider>
                  {listNotification.map(item => {
                    if (dayjs(item.createdAt).format(format) === it) {
                      return (
                        <React.Fragment key={item.id}>
                          <div
                            className={`${
                              item.seenStatus === SeenStatus.UNSEEN ? 'wrap_box' : 'wrap_box-old'
                            }`}
                            seen-status={item.seenStatus}
                            data-id={item.id}
                            onClick={() => switchRouter(item)}
                          >
                            <div
                              className={`${
                                item.seenStatus === SeenStatus.UNSEEN ? 'box-new' : 'box-old'
                              }`}
                            >
                              <div className="flex items-center justify-between">
                                <span className="box-title">
                                  {item.group === GroupNotify.ORGANIZATION
                                    ? item.subject
                                    : formatMessage(item.subject)}
                                </span>
                                <span className="box-time">
                                  {dayjs(it).format(format) === dayjs().format(format)
                                    ? time(item.createdAt)
                                    : dayjs(item.createdAt).format('HH:mm DD-MM-YYYY')}
                                </span>
                              </div>
                              <Typography.Paragraph
                                ellipsis={{ rows: 2, tooltip: true }}
                                className="box-content"
                              >
                                {item.group === GroupNotify.ORGANIZATION ? (
                                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                                ) : (
                                  replaceContent(item)
                                )}
                              </Typography.Paragraph>
                            </div>
                          </div>
                          {item.seenStatus === SeenStatus.SEEN && <hr />}
                        </React.Fragment>
                      );
                    }
                  })}
                </>
              ))
            )}
          </>
        )}
      </div>
    );
  }, [listNotification, listDate, loading, language]);

  const itemTabs: TabsProps['items'] = [
    {
      key: String(GroupNotify.ORGANIZATION),
      label: formatMessage('notifications.tabs.organizations'),
      children: TabContent,
    },
    {
      key: String(GroupNotify.INTERNAL),
      label: formatMessage('notifications.tabs.internal'),
      children: TabContent,
    },
  ];

  const onChange = (v: string) => {
    setFilterGroup(Number(v));
    setListNotification([]);
  };

  const onLoadMore = () => {
    getListNotificationCall
      .execute(
        { current: (infoNotify?.info?.current || 0) + 1, pageSize: 5 },
        {
          filter: {
            group: { value: filterGroup, op: '$eq' },
          },
        },
      )
      .then(res => {
        const mergeArr = [...listNotification, ...res.data];
        setListDate(getListDateNotify(mergeArr));
        setListNotification(prev => [...prev, ...res.data]);
        setInfoNotify({
          info: res.info,
          moreInfo: res.moreInfo,
        });
      });
  };

  const renderContent = (
    <>
      {user?.isOrganizationOwner ? (
        <Tabs activeKey={String(filterGroup)} items={itemTabs} onChange={onChange} />
      ) : (
        TabContent
      )}
      {infoNotify?.info?.totalPages !== 0 &&
        infoNotify?.info?.current !== infoNotify?.info?.totalPages && (
          <div className="bg-white w-full text-[1.4rem] mt-4 underline text-[#F26D21] text-center">
            <span className="w-fit cursor-pointer" onClick={onLoadMore}>
              {formatMessage('notifications.more')}
            </span>
          </div>
        )}
    </>
  );

  const itemsDropdown: MenuProps['items'] = React.useMemo(() => {
    return [
      {
        label: (
          <div className="wrap_text">
            <Avatar
              src={user?.avatarPicture || imgAvatar}
              size={'large'}
              className="avatar flex items-center justify-center"
              icon={<UserOutlined />}
            />
            <div className="wrap_right">
              <span className="title">{user?.username}</span>
              <span className="sub">{user?.name}</span>
            </div>
          </div>
        ),
        key: '0',
        onClick: () => navigation(routerViewProfile.path),
      },
      {
        label: (
          <div className="wrap_text">
            <Avatar src={ImgBackHome} className="avatar !border-transparent" />
            <div className="text">{formatMessage('common.backToSystem')}</div>
          </div>
        ),
        key: '1',
        onClick: () => {
          dispatch(organizationsStore.actions.removeOrganization());
          navigation(routerOrganizations.path);
          authenticationPresenter.getProfile();
        },
        className: !organization ? '!hidden' : '',
      },
      {
        label: (
          <div className="wrap_text">
            <Avatar src={ImgLogout} className="avatar !border-transparent" />
            <div className="text">{formatMessage('common.logout')}</div>
          </div>
        ),
        key: '2',
        onClick: () => {
          ConfirmReStart({
            modifiers: 'orange',
            icon: <Icon.LogOut className="text-white w-[3rem] h-[3rem]" />,
            okText: formatMessage('common.yes'),
            title: formatMessage('common.logout.title'),
            handleOk: () => {
              const autoAccess = Cookies.get('autoAccess');
              if (autoAccess) {
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = CONFIG.LOGIN_PAGE;
                document.body.appendChild(iframe);
                const data = {
                  message: 'logoutSSO',
                };
                iframe.onload = () => {
                  iframe.contentWindow?.postMessage(data, '*');
                };
                window.addEventListener('message', event => {
                  if (event.data && event.data.message === 'logoutComplete') {
                    store.dispatch(removeProfile());
                    store.dispatch(profileStore.actions.setIsLoggingOut(true));
                    window.location.href = CONFIG.LOGIN_PAGE;
                  }
                });
              } else {
                store.dispatch(removeProfile());
                store.dispatch(profileStore.actions.setIsLoggingOut(true));
                window.location.href = CONFIG.LOGIN_PAGE;
              }
            },
            handleCancel: () => {},
          });
        },
      },
    ];
  }, [user, language, organization]);

  return (
    <div className="header-component item-hover__icon intro-x" id="header">
      <ChangeLanguage />
      <IFrame />
      {organization == null && user?.type === typeUser.Organization ? (
        <div className="notification-badge" ref={refNotify}>
          <Popover
            overlayClassName="main-notification"
            id="notification"
            content={renderContent}
            title={
              <div className="flex items-center justify-between w-full">
                <span className="w-1/2 title">{formatMessage('notifications.title')}</span>
                <Button
                  className="btn-notify"
                  onClick={() => {
                    if (infoNotify.moreInfo?.totalUnseen === 0) return;
                    notificationsMarkAllAsSeenCall
                      .execute({ group: filterGroup })
                      .then(() =>
                        handleGetListNotificationCall(infoNotify?.info?.current, filterGroup),
                      )
                      .catch(console.error);
                  }}
                >
                  {formatMessage('notifications.readAll')}
                </Button>
              </div>
            }
            placement="bottomRight"
            trigger="click"
            open={open}
            getPopupContainer={(node: any) => node.parentNode}
            onOpenChange={handleOpenChange}
          >
            <Badge
              count={infoNotify.moreInfo?.totalUnseen || 0}
              overflowCount={9}
              className="icon-badge"
            >
              <Icon.Bell />
            </Badge>
          </Popover>
        </div>
      ) : (
        <></>
      )}
      <div className="dropdown" ref={refDropdown}>
        <Dropdown
          menu={{ items: itemsDropdown }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('header') as any}
          className="cursor-pointer"
        >
          <Avatar
            onClick={(e: any) => {
              e.preventDefault();
              setIsDown(!isDown);
            }}
            src={user?.avatarPicture || imgAvatar}
            size={'large'}
            className="w-[4.8rem] h-[4.8rem] flex items-center justify-center"
            icon={<UserOutlined />}
          />
        </Dropdown>
        {!isDown ? (
          <Icon.ChevronDown size={16} className="icon-upDown" />
        ) : (
          <Icon.ChevronUp size={16} className="icon-upDown" />
        )}
      </div>
    </div>
  );
};

export default React.memo(HeaderComponent);
